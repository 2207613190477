<template>
  <v-sheet 
    tile
    class="completion-progress text-center"
    color="transparent"
  >
    <h6 
      v-if="showText"
      class="subtitle-2 mb-4"
      :class="color+'--text'"
    >
      {{ completion }} % completo
    </h6>
    <span 
      v-if="legend" 
      class="legend min text-caption text--secondary"
      :style="{ 'bottom': ((height/2) - 10) + 'px' }"
    >{{ min }}</span>
    <v-progress-linear
      v-model="completion"
      class="bar"
      :color="color"
      :height="height"
      :background-color="backgroundColor"
      background-opacity=".25"
    />
    <span 
      v-if="legend" 
      class="legend max text-caption text--secondary"
      :style="{ 'bottom': ((height/2) - 10) + 'px' }"
    >{{ max }}</span>
  </v-sheet>
</template>

<style scoped>

  .completion-progress {
    position: relative;
  }

  .completion-progress .bar {
    pointer-events: none;
  }

  .completion-progress .legend {
    position: absolute;
  }
  .completion-progress .legend.min {
    left: -.5rem;
    transform: translateX(-100%);
  }
  .completion-progress .legend.max {
    right: -.5rem;
    transform: translateX(100%);
  }
  
</style>

<script>
  export default {
    props: {
      completion: {
        type: Number,
        default: 0,
      },
      height: {
        type: Number,
        default: 4,
      },
      color: {
        type: String,
        default: 'accent',
      },
      showText: {
        type: Boolean,
        default: true,
      },
      legend: {
        type: Boolean,
        default: false,
      },
      min: {
        type: Number,
        default: 0,
      },
      max: {
        type: Number,
        default: 100,
      },
      backgroundColor: {
        type: String,
        default: 'secondary',
      },
    },
    computed: {
      
    }
  }
</script>